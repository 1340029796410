import { ReactNode } from 'react';
import { Client } from 'helpers/hooks/useEmployeeDetails';
import { TFunction } from 'i18next';
import { BaseSchema } from 'yup';
import { Tooltip, Typography } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { CLIENT, CORPORATE_CLIENT, OPERATIONS, OPERATIONS_SUPERVISOR } from '@globalConstants';
import i18n from '@i18n';
import { NubiTheme } from '@theme';
import { formatDateV2, parseAmount } from '@utils';
import { Product } from '../useProducts';

const t = i18n.t;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface RowItem<T, O = unknown> {
  label: string;
  value: (data: T, client: Client) => ReactNode;
  inputName?: Extract<keyof Product, string>;
  inputType?: 'select';
  getInitialValue?: (data: T, t?: TFunction) => unknown;
  ActionComponent?: ReactNode;
  customStyle?: {
    label?: SystemStyleObject<NubiTheme> | ((theme: NubiTheme) => SystemStyleObject<NubiTheme>);
    value?: SystemStyleObject<NubiTheme> | ((theme: NubiTheme) => SystemStyleObject<NubiTheme>);
  };
  shouldHideEdit?: (item: T) => boolean;
  inputValidation?: BaseSchema;
  props?: {
    multiple: boolean;
    showCheckbox: boolean;
  };
  editable?: boolean;
  internalFeature?: boolean;
}

export const CATEGORIES_EDITOR_ROLES = [
  OPERATIONS,
  OPERATIONS_SUPERVISOR,
  CLIENT,
  CORPORATE_CLIENT,
];

export const ROWS: RowItem<Product, string>[] = [
  {
    label: 'COMMON:CUSTOMER_NAME',
    value: (product) => product.customer?.legal_name,
    internalFeature: true,
  },
  { label: 'COMMON:PRODUCT', value: (product) => product.product },
  { label: 'COMMON:CVU', value: (product) => product.cvu },
  { label: 'COMMON:ALIAS', value: (product) => product.alias },
  {
    label: 'EMPLOYEES:DETAIL:BENEFITS_AVAILABLE',
    value: (product) =>
      product.categories
        .map((category) => {
          if (category === 'GENERIC') {
            return 'Ilimitado';
          }
          return category.charAt(0) + category.substring(1).toLocaleLowerCase();
        })
        .join(', '),
    inputName: 'categories',
    getInitialValue: (product) => product.categories,
    inputType: 'select',
    props: {
      multiple: true,
      showCheckbox: true,
    },
    shouldHideEdit: (product) =>
      product.categories?.[0] === 'No se pudieron cargar los beneficios. Intente nuevamente.',
    editable: true,
  },
  {
    label: 'EMPLOYEES:DETAIL:BALANCE_AVAILABLE',
    value: (product) =>
      product.balance_available === undefined ? (
        <Tooltip title={t('COMMON:HIDE_AMOUNT_TOOLTIP')} arrow>
          <Typography variant='body1' component='span'>
            {t('COMMON:HIDE_AMOUNT')}
          </Typography>
        </Tooltip>
      ) : (
        parseAmount(product.balance_available)
      ),
  },
  {
    label: 'EMPLOYEES:DETAIL:ACCOUNT_CREATED_AT',
    value: (product) => (product.created_at ? formatDateV2(product.created_at) : undefined),
  },
  {
    label: 'COMMON:UPDATED_AT',
    value: (product) => (product.modified_at ? formatDateV2(product.modified_at) : undefined),
  },
  {
    label: 'EMPLOYEES:DETAIL:ENABLE_DISPERSION',
    value: () => {
      return null;
    },
  },
];

export const EDIT_ERRORS = {
  SAME_PRODUCT_CATEGORIES: '4001',
};
