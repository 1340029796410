const translations = {
  COMMON: {
    ADD_PRODUCT: 'Agregar Producto',
    ADD_SEGMENT: 'Agregar Segmento',
    ACCREDITED: 'Acreditado',
    ACCEPT: 'Aceptar',
    ADD: 'Agregar',
    ACCOUNT_NUMBER: 'Número de cuenta',
    ACCOUNT_LINK_TITLE: 'Descargar cuentas',
    ADDITIONAL_DATA: 'Información adicional',
    ADJUSTMENT_CREDIT: 'Crédito por ajuste de saldo manual',
    ADJUSTMENT_DEBIT: 'Débito por ajuste de saldo manual',
    ALIAS: 'Alias',
    ALL: 'Todos',
    AMOUNT: 'Importe',
    APP_TITLE: 'Nubi Backoffice',
    ARE_YOU_SURE: '¿Estás seguro?',
    BALANCE: 'Saldo',
    BALANCE_RECOVERY_ACCOUNT: 'Recupero de saldo de cuenta n° {{accountNumber}}',
    BALANCE_RECOVERY: 'Recupero de saldo',
    BIRTH_DATE: 'Fecha de nacimiento',
    CANCEL: 'Cancelar',
    CHOOSE_COMPANY: 'Elegí razón social',
    CHOOSE_SEGMENT_COMPANY: 'Elegí segmento y razón social',
    CHOOSE_SEGMENT: 'Elegí el segmento',
    CLEAR: 'Borrar',
    CARD: 'Tarjeta',
    CATEGORY: 'Rubro',
    COLLAPSE_FILTERS: 'Contraer filtros',
    COMMERCE: 'Comercio',
    COMPANY: 'Razón social',
    COMPANY_GROUP: 'Grupo empresarial (opcional)',
    COMPANY_KEY: 'Clave de empresa',
    COMPANY_NAME: 'Nombre de la razón social',
    COMPLETED: 'Finalizado',
    CONCEPT: 'Concepto',
    CONFIRM: 'Confirmar',
    CONTINUE: 'Continuar',
    CORPORATE_DISPERSION: 'Dispersión tarjeta jurídica',
    CUIL: 'CUIL',
    CUIT: 'CUIT',
    CURRENCY: 'Moneda',
    CUSTOMER_NAME: 'Razón social',
    CUSTOMER_TYPE: 'Tipo de cliente',
    CREATED_AT: 'Fecha de creación',
    CVU: 'CVU',
    DATE: 'Fecha',
    DELETE: 'Eliminar',
    DISPERSION: 'Dispersión de fondos',
    DNI: 'DNI',
    DOWNLOAD: 'Descargar',
    EMAIL: 'Email',
    ENTITY: 'Razón social',
    EXPAND_FILTERS: 'Expandir filtros',
    EXPORT: 'Exportar',
    CONTACT_EMAIL: 'Email de contacto',
    PROVIDER: 'Proveedor',
    CARD_TYPE: 'Tipo de tarjeta',
    EXPIRATION_DATE: 'Vencimiento',
    COMPANY_TYPE: 'Tipo de empresa',
    FAILED: 'Error',
    FILE: 'archivo',
    FILTER: 'Filtrar',
    FANTASY_NAME: 'Nombre de fantasía',
    FIRST_NAME: 'Nombre',
    FROM_DATE: 'Fecha desde',
    FULL_NAME: 'Nombre y apellido',
    FUNDING: 'Transferencia de fondos',
    FUNDS_TRANSFER: 'Transferencia de fondos',
    GENDER: 'Género',
    GO_BACK: 'Volver',
    HELLO: '¡Hola!',
    HIDE_AMOUNT: '$****',
    HIDE_AMOUNT_TOOLTIP: 'Saldo oculto por ser producto Beneficio',
    INCOMPLETE: 'Incompleto',
    LAST_FOUR_DIGITS: 'Últimos 4 dígitos',
    LAST_NAME: 'Apellido',
    LOADING_OPTIONS: 'Cargando opciones...',
    NAME: 'Nombre',
    MANUAL_ADJUSTMENT: 'Ajuste manual',
    MONTH: 'Mes',
    NO_INFORMATION: '- Sin información -',
    NO_BALANCE: '$ -',
    NO_RESULTS_SEARCH: 'No se encontraron resultados.',
    NO: 'No',
    OPTION_NOT_FOUND: 'No se encontró la opción',
    OPTIONS_ERROR: 'Ocurrió un error al cargar las opciones',
    PENDING: 'Pendiente',
    PHONE_NUMBER: 'Número de teléfono',
    PROCESSING: 'En proceso',
    PRODUCT: 'Producto',
    REGISTER_COMPANY_IN_PRISMA: 'Alta de cliente en Prisma',
    SAVE: 'Guardar',
    SEARCH: 'Buscar',
    SEE_MOVEMENTS: 'Ver movimientos',
    SEE_REPORT: 'Ver reporte',
    SEE_REPORTS: 'Ver reportes',
    SEGMENT: 'Segmento',
    SELECT_COMPANY: 'Seleccioná una razón social',
    SELECT_DATE_PLACEHOLDER: 'Seleccionar fecha',
    SELECT_PLACEHOLDER: 'Seleccioná una opción',
    SELECT_SEGMENT: 'Seleccioná un segmento',
    SELECT_ERROR: 'Ocurrió un error al cargar las opciones',
    SIGN_IN: 'Iniciar Sesión',
    SIGN_OUT: 'Cerrar Sesión',
    STATUS: 'Estado',
    SOON: 'Próximamente',
    TEMPLATE_FILE_NAME: '{{type}}_template.xlsx',
    TEMPLATE_LINK_TITLE: 'Descargar plantilla',
    TOOLTIP: {
      DISPERSION:
        'Descargá aquí una plantilla .xlsx para completar el archivo de dispersión que debes enviar.',
      PROSPECT:
        'Descargá aquí una plantilla .xlsx para completar el archivo de pre-altas que debes enviar.',
    },
    TOTAL: 'Total',
    TOTAL_BALANCE: 'Balance total',
    TO_DATE: 'Fecha hasta',
    TYPE: 'Tipo',
    WALLET_ID: 'Wallet ID',
    UPDATED_AT: 'Última modificación',
    USER: 'Usuario',
    USER_ID: 'UserId',
    UNDERSTOOD: 'Entendido',
    YEAR: 'Año',
    YES: 'Si',
    ERRORS: {
      GENERIC_ERROR_TITLE: 'Ocurrió un error',
      NETWORK_ERROR: '¡Uy! Parece que no estás conectado a internet.',
      DEFAULT_ERROR: '¡Uy, algo salió mal! Volvé a intentar más tarde.',
      INACTIVITY_LOGOUT: '¡Tu sesión expiró! Por favor, volvé a iniciar sesión.',
      LOGIN_DEFAULT_ERROR: '¡Uy, algo salió mal! Volvé a iniciar sesión.',
      CONECCTION_ERROR: 'Hubo un problema para conectar con el servidor',
      INVALID_CREDENTIALS: 'El usuario y/o contraseña son incorrectos',
      NOT_SELECTED_COMPANY_OR_SEGMENT: 'Seleccione segmento y razón social',
    },
    FILE_UPLOADS: {
      DRAG_AND_DROP: 'Soltar archivo aquí',
      BUTTON: 'Seleccionar archivo',
      DROPZONE_TEXT: 'Arrastrá y soltá tu archivo CSV, XLS o XLSX aquí',
      SUCCESS_ALERT: 'El archivo se subió exitosamente',
      ERROR_ALERT: 'Ocurrió un error al intentar subir el archivo',
      SEND_FILE_BUTTON: 'Enviar archivo',
      LINE_NUMBER: 'Línea {{number}}',
    },
    PAGINATION: {
      ROWS_PER_PAGE: 'Filas por página',
      CANCEL_SELECTION: 'Anular la selección',
      SELECT_REMAINING_ROWS: 'Seleccionar las {{count}} filas restantes',
      LABEL_DISPLAYED_ROWS: 'página {{page}} de {{total}} ({{count}} registros)',
      ALL_ROWS_SELECTED: 'Las <strong>{{count}}</strong> filas están seleccionadas',
      SELECTED_ROWS_one: '<strong>{{count}}</strong> fila seleccionada',
      SELECTED_ROWS_other: '<strong>{{count}}</strong> filas seleccionadas',
    },
    STATUSES: {
      OK: 'Ok',
      CANCELLED: 'Cancelado',
      ERROR: 'Error',
      REJECTED: 'Rechazado',
    },
    TRANSACTIONS: {
      EXTERNAL_USERS: {
        CASHOUT_AUTH: 'Consumo',
        CASHOUT_AUTH_GP: 'Consumo',
        CASHOUT_AUTH_PRISMA: 'Consumo',
        CASHIN_PPCAAS: 'Dispersión en tarjeta personal',
        CASHIN_TJURIDICA: 'Dispersión en corporativa',
        CASHIN_AUTH_CANCEL_GP: 'Devolución',
        ADJUST_CRED_CASHIN: 'Crédito por ajuste manual',
        ADJUST_DEB_CASHOUT: 'Débito por ajuste manual',
        CASHOUT_RECOVERY_ALL: 'Recupero de saldo',
        CASHOUT_RECOVERY: 'Recupero de saldo',
        CASHOUT_RECOVERY_PRISMA: 'Recupero de saldo',
        CASHOUT_RECOVERY_GP: 'Recupero de saldo',
        CASHOUT_EXTRACASH_PRISMA: 'Retiro Extracash',
        CASHOUT_WITHDRAW_PRISMA: 'Retiro ATM',
        RAPIPAGO_CASHOUT: 'Retiro Rapipago',
        CASHIN_EXTRACASH_CANCEL_PRISMA: 'Anulación de retiro de dinero Extracash',
        CASHIN_AUTH_CANCEL_PRISMA: 'Anulación de pago',
        CASHIN_WITHDRAW_CANCEL_PRISMA: 'Devolución retiro de efectivo',
      },
    },
    VALIDATIONS: {
      AMOUNT_MUST_NOT_BE_EMPTY: 'Monto no debe estar vacío',
      AMOUNT_MUST_BE_POSITIVE_NUMBER: 'Monto debe ser un número positivo',
      AT_LEAST_ONE_FIELD_REQUIRED: 'Debe completar al menos un campo',
      BENEFICIO_MUST_NOT_BE_EMPTY: 'Beneficio no debe estar vacío',
      CATEGORY_MUST_NOT_BE_EMPTY: 'El rubro no debe estar vacío',
      CATEGORY_NOT_ALLOWED: 'El rubro es inválido',
      DATE_INVALID: 'Fecha inválida, el formato debe ser DD/MM/AAAA.',
      DATE_VALUE_INVALID: 'Fecha inválida',
      BIRTH_DATE_MIN: 'La fecha de nacimiento debe ser mayor a 16 años.',
      BIRTH_DATE_MAX: 'La fecha de nacimiento debe ser menor a 100 años.',
      CUIT_INVALID_LENGTH: 'Longitud inválida de CUIT',
      CUIL_INVALID_LENGTH: 'Longitud inválida de CUIL',
      CUIT_MUST_BE_A_NUMBER: 'CUIL debe ser sólo números',
      CUIT_MUST_NOT_BE_EMPTY: 'CUIL no debe estar vacío',
      DNI_INVALID_LENGTH: 'Longitud inválida de DNI',
      DNI_MUST_BE_A_NUMBER: 'DNI debe ser sólo números',
      DNI_MUST_NOT_BE_EMPTY: 'DNI no debe estar vacío',
      DNI_OR_CUIT_MISMATCH: 'CUIL y DNI no coinciden',
      EMAIL_MUST_NOT_BE_EMPTY: 'Email no debe estar vacío',
      INCONSISTENT_COLUMNS_TITLE: 'Error en las columnas del archivo',
      INCONSISTENT_COLUMNS_DESCRIPTION:
        'La cantidad de columnas no coincide con la esperada. Revisá la línea {{line}}.',
      INVALID_CUIL: 'CUIL inválido',
      INVALID_AMOUNT: 'Monto inválido',
      INVALID_DNI_OR_CUIL: 'CUIL o DNI inválido',
      INVALID_EMAIL: 'Formato de email inválido',
      INVALID_NAME: 'El nombre es inválido',
      INVALID_LASTNAME: 'El apellido es inválido',
      INVALID_TOTAL_VALUE: 'La fila del total es inválida',
      MISSING_COLUMN: 'No se encontró la columna obligatoria "{{column}}"',
      NAME_MUST_BE_NOT_EMPTY: 'Nombre no debe estar vacío',
      NAME_MUST_BE_A_STRING: 'Nombre debe ser texto',
      NAME_AND_LAST_NAME: 'Nombres inválidos',
      LASTNAME_MUST_BE_NOT_EMPTY: 'Apellido no debe estar vacío',
      LASTNAME_MUST_BE_A_STRING: 'Apellido debe ser texto',
      NAME_LASTNAME_MIN_LENGTH: 'Longitud inválida, mínimo dos caracteres.',
      NAME_LASTNAME_MAX_LENGTH: 'Longitud inválida, máximo cuarenta caracteres.',
      ONLY_NUMBERS: 'Debe ser numérico',
      PHONE_INVALID_LENGTH:
        'Ingresá el código de tu País seguido por el de tu Localidad (Ej: 5491101234567).',
      PHONE_INVALID_AREA_CODE: 'Código de área inválido.',
      REPEATED_DNI: 'El DNI se encuentra repetido en otra línea',
      REPEATED_CUIL: 'El CUIL se encuentra repetido en otra línea',
      REPEATED_EMAIL: 'El Email se encuentra repetido en otra línea',
      REQUIRED_FIELD: 'Campo obligatorio',
      PRODUCT_MUST_NOT_BE_EMPTY: 'El producto no debe estar vacío',
      PRODUCT_NOT_ALLOWED: 'El producto es inválido',
      TIPO_TARJ_MUST_NOT_BE_EMPTY: 'Tipo de tarjeta no debe estar vacío',
      INVALID_POSTAL_CODE: 'El código postal debe tener 4 dígitos',
    },
    CLEAR_FILTER: 'Limpiar filtros',
    CLEAR_FORM: 'Limpiar campos',
  },
  //  new clients section
  CLIENTS: {
    ADD_CLIENT: 'Nuevo cliente',
    BALANCE_TAB_TITLE: 'Saldo',
    CREATED_AT: 'Fecha de alta',
    CREATE_CLIENT_BUTTON: 'Alta de cliente',
    DETAILS_TAB_TITLE: 'Detalles',
    REPORTS_TAB_TITLE: 'Reportes',
    MOVEMENTS_TITLE: 'Movimientos',
    SELECT_COMPANY: 'Seleccioná una razón social',
    DETAIL: {
      POSTAL_ADDRESS: {
        TITLE: 'Dirección postal',
        CITY: 'Ciudad',
        STATE: 'Provincia',
        STREET: 'Calle',
        NUMBER: 'Número',
        FLOOR_AND_APARMENT: 'Piso / Depto.',
        FLOOR: 'Piso',
        APARMENT: 'Depto',
        POSTAL_CODE: 'Código postal',
      },
      PRODUCTS_AVAILABLE: { TITLE: 'Productos disponibles' },
      ADD_POSTAL_ADDRESS: {
        TITLE: 'Agregar dirección postal',
        SUCCESS: 'Cambios guardados con éxito.',
      },
      DONT_HAVE_POSTAL_ADDRESS: {
        OPERACIONES:
          'Aún no tenemos cargada la dirección postal. Recordá que podés hacerlo haciendo click en "Agregar dirección postal" desde las acciones arriba a la derecha de la pantalla.',
        CLIENTE:
          'Aún no tenemos cargada la dirección postal, por favor escribinos a la dirección de mail de soporte si tenes alguna duda.',
      },
      PUBLIC: 'Público',
      PRIVATE: 'Privado',
    },

    ASSIGN_PRODUCT_MODAL: {
      FAILED_TITLE: 'Ocurrió un error',
      SUCCESS_TITLE: 'Producto agregado correctamente.',
      COMPLETE_DESCRIPTION:
        '¡Listo! Has enviado con éxito la información de los siguientes productos:',
      FAILED_DESCRIPTION: 'No se ha podido agregar los siguientes productos:',
      PARTIAL_DESCRIPTION:
        '¡Listo! Has enviado con éxito la información de los productos: {{result}}.Pero los siguientes productos no pudieron ser agregados:',
    },
    CREATE_CLIENT: {
      SUCCESS_TITLE: 'Cliente añadido correctamente.',
      SUCCESS_DESCRIPTION: '¡Listo! ¡Has enviado con éxito la información del nuevo cliente!',
      SUCCESS_WITH_ERROR_DESCRIPTION:
        '¡Listo! ¡Has enviado con éxito la información del nuevo cliente!, pero se encontraron errores:',
      ERROR_DESCRIPTION:
        'No se ha podido crear el nuevo cliente, por favor revise los siguientes errores encontrados:',
      ERROR_DETAILS: {
        GENERIC_GROUP_INFO: 'Error en la información del grupo',
        GENERY_COMPANY_PROCESS_RESULT: 'Error en el resultado del proceso de la empresa',
        GENERIC_SEGMENT_RESULT: 'Error en la asignación del segmento',
        GENERIC_S3_FOLDERS: 'Error en la creación de la carpeta en S3',
        GENERIC_PROSPECT_ADRESS: 'Error en la dirección del prospecto',
        EXIST_COMPANY_PROCESS_RESULT: 'La Razón Social de la empresa ya existe',
        EXIST_SEGMENT_RESULT: 'El Segmento de la compañía ya existe',
        EXIST_PROSPECT_ADRESS: 'La dirección del prospecto ya existe',
        EXIST_GROUP_NAME: 'El Grupo empresarial ya existe',
        INVALID_LEGAL_IDENTIFICATION: 'Identificación legal no válida',
      },
      ADD_SEGMENT_MODAL: {
        LIMITS_ERROR: 'Error en la duplicación de límites por defecto',
        CUSTOMER_SEGMENT_CREATE_ERROR: 'Error en la asignacion de subSegmentos',
        INCOMPLETE_PROCESS: {
          TITLE: 'Se procesó con algunos errores',
          DESCRIPTION: 'No pudimos registrar el Segmento en la base de datos',
        },
        SUCCESS_TITLE: 'Se agregó con éxito el Segmento',
        SUCCESS_SEGMENT_CREATION:
          'El segmento {{segmentDisplay}} fue creado con el identificador número: {{number}}',
        BAD_REQUEST_TITLE: 'No se pudo dar de alta el Segmento',
        BAD_REQUEST_DESCRIPTION:
          'Nombre de segmento ingresado existe para la razón social seleccionada.',
      },
    },
    ERRORS: {
      REGISTER_COMPANY_IN_PRISMA:
        'Hay un problema para validar si el cliente ya existe en Prisma, por favor comunícate con IT',
    },
  },
  EMPLOYEES: {
    ASSIGN_PRODUCT_TITLE: 'Seleccionar nuevo producto',
    ASSIGN_PRODUCT_DESCRIPTION: 'Seleccioná nuevo producto para tu colaborador',
    PRODUCT_LABEL: 'Producto *',
    CATEGORY_LABEL: 'Rubro *',
    SEGMENT_LABEL: 'Segmento',
    INVALID_PRODUCTS_SEARCH: 'Seleccioná una razón social para filtrar por productos',
    TITLE: 'Colaboradores',
    SEARCH_CLIENT: 'Buscar cuentas',
    MULTIPLE_SEARCH: 'Búsqueda múltiple por CUIL',
    MULTIPLE_SEARCH_TOOLTIP: 'Ingresá el CUIL y presioná la tecla Enter para añadirlo a la lista',
    MULTIPLE_SEARCH_MAX: 'Se permite un máximo de 10 CUIL.',
    MULTIPLE_SEARCH_MIN: 'El listado debe contar con al menos 2 CUIL.',
    MULTIPLE_SEARCH_DUPLICATED_VALUE: 'El CUIL ingresado ya se encuentra en el listado.',
    REQUIRES_LEGAL_CARD: '¿Requiere tarjeta jurídica?',
    PROSPECT_CREATE: 'Prealta de colaborador',
    STATUS: {
      REGISTERED: 'Registrado',
      PROSPECT: 'Prospecto',
      ONBOARDING: 'Onboarding',
      UNKNOWN: '-',
      DELETED: 'Eliminado',
    },
    ASSIGN_PRODUCT: {
      BUTTON: 'Asignar nuevo producto',
      MODAL_TITLE: 'Seleccionar nuevo producto',
      MODAL_DESCRIPTION: 'Seleccioná nuevo producto para tu colaborador',
      CONFIRM_MODAL_TITLE: 'Confirmación nuevo producto asignado',
      CONFIRM_MODAL_DESCRIPTION:
        'Tus colaboradores ya tienen el producto: {{product}} con su nueva tarjeta NubiZ activa para el/los rubros: {{categories}}',
      ERROR_TITLE: 'Se han encontrado errores',
      ERROR_DESCRIPTION: 'Lo sentimos, no hemos podido agregar {{product}} debido a:',
      DIFFERENT_CUSTOMERS_ERROR_TITLE:
        'Se han encontrado errores, colaboradores de distintas razones sociales',
      DIFFERENT_CUSTOMERS_ERROR_DESCRIPTION:
        'Por favor, seleccioná colaboradores de una única razón social para continuar',
      SEGMENT_ERROR: 'El segmento seleccionado no es válido para el colaborador',
    },
    RETRIEVE_BALANCE: {
      PRODUCTS_NOT_ALLOWED_TITLE:
        'Sólo podés recuperar saldo de los productos con tarjeta corporativa o almuerzo',
      PRODUCTS_NOT_ALLOWED_DESCRIPTION:
        'Algunos productos seleccionados no cumplen esta condición. Podés utilizar previamente el filtro de producto para facilitar tu operación.',
      POSITIVE_BALANCE_TITLE: 'Se han encontrado errores, colaboradores sin saldo',
      POSITIVE_BALANCE_DESCRIPTION:
        'No podés continuar con esta acción porque sólo podes recuperar saldo si la cuenta tiene saldo positivo',
      MODAL_TITLE: '¿Querés continuar con esta acción?',
      MODAL_MESSAGE:
        'Estás a punto de recuperar <bold>{{total}}</bold> entre <bold>{{count}}</bold> cuentas de <bold>{{companyName}}</bold>. ¿Estás de acuerdo con este monto?',
      MODAL_MESSAGE_WITH_WARNING:
        'Estás a punto de recuperar <bold>{{total}}</bold> entre <bold>{{count}}</bold> cuentas de <bold>{{companyName}}</bold>. <br><br> Recordá que sólo podes recuperar saldo si la cuenta tiene saldo positivo. <br><br> ¿Estás de acuerdo con este monto?',
      RECOVERY_SUCCESS_TITLE: 'Te confirmamos que recupertaste tu saldo de manera exitosa',
      RECOVER_SUCCESS_BODY: 'Podes ver el mismo actualizado en la opción Mi saldo.',
      RECOVERY_ERROR_TITLE: 'Ocurrió un error inesperado',
      RECOVER_ERROR_BODY:
        'Por favor volvé a intentarlo. Si el problema persiste por favor contactate con Soporte: contacto_autogestión@nubi.com.ar',
    },
    DETAIL: {
      ACCOUNT: 'Cuenta',
      ACCOUNTS: 'Cuentas',
      EMPTY_LAST_LOGIN_AT: 'Aún no se registra ingreso',
      LAST_LOGIN_AT: 'Último ingreso a la app',
      ACCOUNT_CREATED_AT: 'Fecha de alta',
      ACCOUNT_NUMBER: 'Número de cuenta',
      ALLOW_DISPERSION: 'Habilitar dispersión',
      ALLOW_DISPERSION_MODAL_DESCRIPTION:
        '¿Estás seguro que querés habilitar la dispersión para esta cuenta? Se mostrará esta cuenta dentro del listado de dispersión rápida.',
      ENABLE_DISPERSION: 'Habilitado para dispersión',
      BALANCE_TOTAL: 'Saldo',
      BALANCE_FROZEN: 'Saldo inmovilizado',
      BALANCE_AVAILABLE: 'Saldo disponible',
      BALANCE_RECOVERY: 'Recupero de saldo',
      BENEFITS_AVAILABLE: 'Rubro',
      CLIENT_DETAILS: 'Detalles',
      //TODO: Confirm Modal is used for both clients and employees, we should refactor it
      CONFIRM_MODAL_DESCRIPTION:
        '¿Deseas actualizar la información del colaborador? Datos a modificar: {{changedValues}}',
      CONFIRM_MODAL_TITLE: 'Modificación de colaborador',
      DISPERSE: 'Dispersar',
      EDIT_CATEGORIES: 'Editar rubro',
      DISABLE_DISPERSION: 'Deshabilitar dispersión',
      DISABLE_DISPERSION_MODAL_DESCRIPTION:
        '¿Estás seguro que querés deshabilitar la dispersión para esta cuenta? No se mostrará esta cuenta dentro del listado de dispersión rápida.',
      EDIT_CATEGORIES_SUCCESS: 'Cambios guardados con éxito.',
      EDIT_CATEGORIES_FAILED_SAME_PRODUCT_TITLE: 'Atención',
      EDIT_CATEGORIES_FAILED_SAME_PRODUCT_DESCRIPTION:
        'El colaborador ya posee este combinación de rubros asociada. Debe contener al menos un rubro diferente para continuar',
      GP_ACCOUNT_NUMBER: 'Número de cuenta GP',
      MOVEMENTS: 'Movimientos',
      NO_PRODUCTS_MESSAGE: 'El usuario no posee productos',
      OVERVIEW: 'Resumen',
      SUCCESS_EDIT: 'Se ha modificado el colaborador con éxito.',
      SUCCESS_RESEND_MAIL: 'El mail fue enviado correctamente.',
      PRODUCT: 'Producto',
      SUCCESS_RESEND_EMAIL: 'El email fue enviado correctamente.',
      PRODUCTS: 'Productos',
      PRODUCTS_CARD_TITLE: 'Tarjeta #{{ count }}',
      PRODUCTS_ACCOUNT_TITLE: 'CVU',
      EDIT: 'Editar',
      REQUEST_PHYSICAL_CARD: {
        TITLE_MENU: 'Solicitar plástico',
        CONFIRM_MODAL_TITLE: '¿Querés continuar con esta acción?',
        CONFIRM_MODAL_TEXT:
          'Esta tarjeta se enviará a la dirección <bold>{{postalAddress}}, {{floor}}{{apartment}}{{cityName}}, {{stateName}}</bold>, de la razón social <bold>{{legalName}}</bold>. Para modificarla comunicarse con IT.',
        CONFIRM_MODAL_TEXT_FLOOR: 'Piso {{floor}}, ',
        CONFIRM_MODAL_TEXT_APARTMENT: 'Dpto {{apartment}}, ',
        MISSING_POSTAL_ADDRESS_TITLE: 'No podemos realizar esta acción',
        MISSING_POSTAL_ADDRESS_TEXT:
          'El cliente no tiene cargada una dirección postal. Recordá que podes cargarla desde el BO en la opción Clientes/Agregar dirección postal',
        GET_POSTAL_ADDRESS_ERROR: 'Hubo un error al obtener la dirección postal',
        SUCCESS_TITLE: '¡Listo! ¡La tarjeta pronto estará en camino!',
        SUCCESS_TEXT:
          'Te confirmamos que la tarjeta ya quedó solicitada. Llegará en aproximadamente 2 días a la dirección del cliente.',
        ERROR: 'Ocurrió un error, por favor comunicate con IT.',
        PHYSICAL_CARD_ALREADY_REQUESTED: 'La tarjeta física ya se solicitó',
      },
      REGISTER_STATUS: 'Estado del registro',
      RESEND_EMAIL_BUTTON: 'Reenviar registro',
      RESEND_EMAIL: 'Reenvío de email de registro',
      RESEND_EMAIL_DESCRIPTION: 'Se va a reenviar un email a <1>{{email}}</1>',
      RETRIEVE_BALANCE: 'Recuperar Saldo',
      RETRIEVE_BALANCE_AMOUNT_PLACEHOLDER: 'Ingresá el monto a recuperar',
      RETRIEVE_BALANCE_TITLE: 'Recuperá el saldo',
      RETRIEVE_BALANCE_TOTAL_HELPER:
        'Vas a recuperar todo el saldo de tu colaborador ({{balance}})',
      RETRIEVE_BALANCE_SUCCESS: '¡Recuperaste tu saldo con éxito!',
      RETRIEVE_PARTIAL_AMOUNT: 'Recuperar un monto parcial del saldo',
      PRODUCTS_REQUEST_PHYSICAL_CARD: 'Solicitar Tarjeta Física',
      VIEW_DETAIL: 'Ver detalle',
      VIEW_ALL_MOVEMENTS: 'Ver todos los movimientos',
      WITHOUT_CATEGORY: 'Sin rubros',
      WITHOUT_PRODUCT: 'N/A',
      CARD_TYPE: {
        PERSONAL: 'Personal',
        BUSINESS: 'Jurídica',
      },
      STATUS: {
        REGISTERED: 'Usuario registrado',
        PRECOMPLETED: 'Usuario pendiente de registro',
        DELETED: 'Usuario eliminado',
        UNDEFINED: 'Dato no provisto o no existente.',
      },
      ERRORS: {
        INVALID_BIRTH_DATE:
          'Verificá que la fecha sea válida en formato DD/MM/AAAA y mayor a 16 años.',
        RETRIEVE_BALANCE_TITTLE: '¡Vaya! Algo salió mal',
        RETRIEVE_BALANCE_CONTENT:
          'Intentá de nuevo en unos minutos o contactá al equipo de soporte.',
        EDIT_ERROR: 'Ocurrió un error al editar los datos del colaborador.',
        BENEFITS_EDIT_ERROR: 'Ocurrió un error al editar los beneficios del colaborador',
        PROVIDER_ERROR:
          'Los datos no fueron actualizados correctamente en el proveedor de tarjetas.',
        CLIENT_FETCH_GENERIC_ERROR: 'Ocurrió un error al obtener la información del colaborador.',
        CLIENT_DETAIL_FETCH_GENERIC_ERROR:
          'Lo sentimos, no logramos cargar la información del colaborador seleccionado. Por favor, reintente más tarde.',
        ACCOUNT_FETCH_GENERIC_ERROR: 'Ocurrió un error al obtener la información de la cuenta.',
        EMAIL_ALREADY_EXISTS: 'Email ya asignado a otro usuario. No se actualizaron los datos.',
        PRODUCTS_FETCH_GENERIC_ERROR:
          'Ocurrió un error al obtener la información de los productos.',
        UNABLE_TO_SEE_TAB: 'El usuario no puede acceder a esta sección.',
        WARNING_FAILED_FIELDS:
          'No pudimos guardar todos los cambios. Los siguientes campos no pudieron ser actualizados: {{fields}}',
        FAIL_SENDIG_MAIL: 'No pudimos enviar el email de registro actualizado al usuario.',
        FAIL_RESEND_EMAIL: 'No se pudo enviar el email, por favor intentá nuevamente.',
        PHONE_ALREADY_EXISTS:
          'Número de teléfono ya asignado a otro usuario. No se actualizaron los datos.',
      },
      VALIDATIONS: {
        AMOUNT_EXCEEDS_TOTAL_BALANCE:
          'El monto a recuperar no puede ser mayor que el total del saldo ({{totalBalance}}).',
      },
    },
    ERRORS: {
      GENERIC_ERROR: 'Ocurrió un error al buscar clientes',
      INVALID_SEARCH:
        'La consulta realizada es inválida. Por favor revisá los parámetros de búsqueda.',
    },
  },
  COMMERCES: {
    ADD_COMMERCE: 'Agregar comercio',
    COMMERCE_SAVED_MESSAGE: 'Ya guardamos el nuevo comercio.',
    COMMERCE_SAVED_TITLE: 'Éxito',
    COMMERCE_DELETED_MESSAGE: 'Ya no está más el comercio en nuestros datos.',
    COMMERCE_DELETED_TITLE: 'Comercio eliminado',
    SECTION_TITLE: 'Gestión de comercios',
    DELETE_COMMERCE: 'Borrar comercio',
    DELETE_WARNING: 'Vas a eliminar el comercio',
    MCC: 'MCC',
    NAME: 'Nombre del comercio',
    BENEFIT: 'Beneficio',
    ERRORS: {
      COMMERCE_NOT_FOUND: 'No se encontró el comercio',
      UNABLE_TO_DELETE: 'Tuvimos un problema intentando eliminar el comercio.',
      UNABLE_TO_SAVE: 'Tuvimos un problema intentando guardar el comercio.',
      REQUIRED_FIELDS_TO_SAVE: 'Los 3 campos son obligatorios, el MCC requiere de 4 dígitos',
      INVALID_SEARCH: 'No se pudo realizar la búsqueda. Verificar el nombre de comercio',
    },
  },
  DISPERSIONS: {
    AMOUNT: 'Monto',
    ACCOUNT: 'Cuenta',
    DISPERSE: 'Dispersar',
    DISPERSION: 'Dispersión',
    ORIGINAL_AMOUNT: 'Monto original',
    REAL_AMOUNT: 'Monto real dispersado',
    CSV_ERRORS_TITLE: 'Encontramos los siguientes errores en tu archivo de dispersión:',
    CANCELLED: {
      TITLE_MODAL_CONFIRM: '¿Querés continuar con esta acción?',
      DESCRIPTION_MODAL_CONFIRM:
        'Estás a punto de cancelar la dispersión de <bold>{{name}}</bold> programada para el día <bold>{{date}}</bold> a las <bold>{{time}}</bold> hrs.',
      SUCCESS: {
        TITLE: 'Dispersión cancelada con éxito',
        DESCRIPTION: 'La dispersión ha sido cancelada correctamente.',
      },
      ERROR: {
        TITLE: 'Error al cancelar la dispersión',
        DESCRIPTION_404: 'No se encontró la dispersión solicitada.',
        DESCRIPTION_500: 'Ocurrió un error en el servidor al intentar cancelar la dispersión.',
        DESCRIPTION_503: 'El servicio de dispersión no está disponible en este momento.',
        DESCRIPTION_410:
          'No es posible cancelar esta dispersión porque quedan menos de 5 minutos para su ejecución.',
      },
    },
    DISPERSION_DATE: 'Fecha programada',
    FILE_DISPERSION: 'Dispersar archivo',
    HEADING: '¡Ya podés comenzar con la dispersión de fondos para {{name}}!',
    INPUT_LABEL_DATE: 'Fecha a dispersar',
    INPUT_LABEL_SWITCH: 'Programar dispersión',
    INSTANT: 'Instantánea',
    MODAL_MESSAGE_INSTANT:
      'Estás a punto de dispersar <1>{{total}}</1> entre <3>{{count}}</3> de tus colaboradores de <5>{{companyName}}</5>. ¿Estás de acuerdo con este monto?',
    MODAL_MESSAGE_SCHEDULED:
      'Estás programando una dispersión de <1>{{total}}</1> entre <3>{{count}}</3> de tus colaboradores de <5>{{companyName}}</5> para que se ejecute el día <7>{{formatDay}}</7> a las <9>{{formatTime}}</9> hrs.',
    MODAL_TITLE: '¿Querés continuar con esta acción?',
    MY_DISPERSIONS: 'Mis dispersiones',
    STATUS_DESCRIPTION_FAILED: 'Ocurrió un error inesperado durante la subida del archivo.',
    STATUS_DESCRIPTION_INCOMPLETE:
      'Recibimos tu archivo pero algunos colaboradores no pudieron ser procesados',
    STATUS_DESCRIPTION_PENDING: 'Recibimos tu archivo y está pendiente a ser procesado.',
    SCHEDULE_TITLE: 'Programar fecha a dispersar',
    SCHEDULE_TEXT:
      'El dinero se dispersará de manera automática en la fecha que hayas seleccionado',
    SELECT_COMPANY: 'Seleccioná la razón social',
    UPLOAD_FILE: 'Subir archivo de dispersión',
    UPLOAD_DATE: 'Fecha de carga',
    ATTEMPTED_AMOUNT: 'Monto original',
    DISPERSED_AMOUNT: 'Monto acreditado',
    DISPERSIONS_SUCCESS_TITLE_INSTANT: '¡Listo! ¡Has enviado tu archivo exitosamente!',
    DISPERSIONS_SUCCESS_BODY_INSTANT:
      'En breve te estaremos enviando por correo los resultados del procesamiento de tu archivo.',
    DISPERSIONS_SUCCESS_TITLE_SCHEDULED: '¡Dispersión programada exitosamente!',
    DISPERSIONS_SUCCESS_BODY_SCHEDULED:
      'Tu dispersión de fondos ha sido programada y se ejecutará en la fecha que has seleccionado.',
    ERRORS: {
      CONFIRMATION_ERROR_TITLE: 'No pudimos dispersar los fondos',
      CONFIRMATION_ERROR_DESCRIPTION:
        'Ocurrió un error inesperado durante la confirmación de la dispersión.',
      EXPIRED_TITLE: 'No pudimos dispersar los fondos',
      EXPIRED_DESCRIPTION:
        'Expiró el tiempo para confirmar la dispersión. Por favor intentalo nuevamente.',
      INSUFFICIENT_BALANCE_TITLE: 'Tenés fondos insuficientes',
      INSUFFICIENT_BALANCE_DESCRIPTION:
        'Lo sentimos, no contás con fondos suficientes para realizar la dispersión. Verificá que tengas el saldo en tu cuenta antes de volver a intentar.',
      INVALID_COMPANIES_TITLE: 'Razones sociales inválidas',
      INVALID_COMPANIES_DESCRIPTION:
        'Las razones sociales asignadas al usuario no son válidas. Por favor contactá a un administrador para poder dispersar fondos.',
      INVALID_FILE_NAME_TITLE: 'Nombre de archivo inválido',
      INVALID_FILE_NAME_DESCRIPTION:
        'El nombre del archivo enviado no coincide con ninguno registrado en la base de datos.',
      FILE_CONTAINS_ERRORS_TITLE: 'Errores en archivo',
      FILE_CONTAINS_ERRORS_DESCRIPTION:
        'Encontramos errores en el archivo que enviaste. Por favor corregilos y volvé a intentarlo',
      DEFAULT_BAD_REQUEST_ERROR: 'Ha ocurrido un error inesperado al subir el archivo',
      UNABLE_TO_FETCH_COMPANIES_TITLE: 'Error al buscar razones sociales',
      UNABLE_TO_FETCH_COMPANIES_DESCRIPTION:
        'Ocurrió un error inesperado al obtener información de las razones sociales.',
    },
    FAST_DISPERSION: {
      TITLE: 'Dispersión rápida',
      NAME: 'Nombre',
      LAST_NAME: 'Apellido',
      CUIL: 'CUIL',
      PRODUCT: 'Producto',
      CATEGORY: 'Rubro',
      EXPORT_BUTTON: 'Exportar',
      NEXT_BUTTON: 'Continuar',
      AMOUNT: 'Monto',
      TITLE_AMOUNT: 'Ingresar monto a dispersar',
      DESCRIPTION_AMOUNT:
        'Se le dispersará el monto indicado a cada una de las cuentas seleccionadas.',
      DISPERSIONS_SUCCESS_TITLE_INSTANT: 'Dispersión en proceso',
      DISPERSIONS_SUCCESS_BODY_INSTANT:
        'En breve te estaremos enviando por correo los resultados del procesamiento de tu dispersión.',
    },
  },
  HOME: {
    BALANCE_AVAILABLE: 'Saldo disponible',
    WELCOME_TITLE_EXTERNAL: '¡Hola, {{companyName}}!',
    WELCOME_BODY_EXTERNAL:
      'Te damos la bienvenida a nuestro sistema de gestión. Aquí encontrarás la información que necesites para gestionar los beneficios de Nubi para tus colaboradores.',
    WELCOME_TITLE_INTERNAL: '¡Hola, {{name}}!',
    WELCOME_BODY_INTERNAL: 'Te damos la bienvenida al sistema de gestión de Nubiz.',
  },
  INVOICES: {
    SELECT_PERIOD: 'Seleccioná el período de los comprobantes',
    ERRORS: {
      DEFAULT_ERROR_TITLE: 'Error al obtener comprobantes',
      DEFAULT_ERROR_BODY:
        'No se pudo obtener el enlace para descargar el comprobante en el período seleccionado.',
      NOT_FOUND_ERROR_TITLE: 'No encontramos el reporte',
      NOT_FOUND_ERROR_BODY: 'No se encontraron comprobantes de gastos en el período seleccionado.',
    },
  },
  LOGIN: {
    GET_STARTED: 'Empezá ahora',
    PASSWORD: 'Contraseña',
    USERNAME: 'Usuario',
    SELECT_COMPANY_TITLE: 'Seleccioná una razón social para comenzar a operar',
    SELECT_COMPANY_BODY: 'Luego podrás elegir otra desde tu perfil',
    WELCOME_BODY: 'Ingresá tus credenciales para acceder a nuestro sistema de gestión',
    WELCOME_TITLE: 'Comenzá a gestionar tus beneficios con Nubiz',
    VALIDATIONS: {
      USERNAME_REQUIRED: 'Usuario es obligatorio',
      PASSWORD_REQUIRED: 'Contraseña es obligatorio',
    },
    ERRORS: {
      ACCOUNT_ERROR_TITLE: 'Tu usuario debe ser configurado',
      NO_ROLES:
        'El usuario no posee roles. Para utilizar el backoffice es necesario tener al menos un rol asignado.',
      NO_PARTNER_DATA:
        'El usuario de autogestión no posee la información necesaria: razones sociales (companies) y/o segmentos (types). Por favor contactá a un administrador.',
    },
  },
  MENU: {
    BALANCE: 'Mi saldo',
    CLIENTS: 'Clientes',
    CURRENCY_RATE: 'Cotizacion Dolar',
    COMMERCES: 'Comercios',
    DISPERSIONS: {
      TITLE: 'Dispersar fondos',
      FAST_DISPERSION: 'Dispersión rápida',
      FILE_DISPERSION: 'Dispersar archivo',
      MY_DISPERSIONS: 'Mis dispersiones',
    },
    EMPLOYEES: 'Cuentas',

    MY_ACCOUNT: 'Mi cuenta',
    PROSPECTS: {
      TITLE: 'Alta de colaborador',
      QUICK_CREATION: 'Pre-alta rápida',
      UPLOAD_FILE: 'Subir archivo',
      SEND_EMAIL: 'Email de registro',
    },
    ADD_SEGMENT: 'Segmentos',
    SIGN_OUT: 'Cerrar sesión',
    REPORTS: 'Reportes',
    CONSUMPTION: {
      TITLE: 'Reportes',
      LIST: 'Listado de consumos',
      INVOICES: 'Comprobantes de gastos',
    },
  },
  EXCHANGE: {
    TITLE: 'Actualización de la cotización del dolar',
    INPUT_PLACEHOLDER: 'Ingresá la cotización del día',
    SCHEDULED_SWITCH_MESSAGE: '¿Querés programar la cotización?',
    INPUT_PLACEHOLDER_SCHEDULE: 'Ingresá la fecha',
    SCHEDULED_MESSAGE: 'Recordá que solo se puede programar hasta un máximo de 2 días.',
    BUTTON_SAVE_TEXT: 'Guardar',
    SUBTITLE: 'Últimas cotizaciones',
    TABLE_DATE_TITLE: 'Fecha',
    TABLE_START_TITLE: 'Vigente desde',
    TABLE_EXCHANGE_TITLE: 'Cotización',
    ERRORS: {
      TITLE_POST: 'Error al crear la cotización',
      MESSAGE_POST: ' - Contactar al equipo Banking.',
      TITLE_GET: 'Error',
      MESSAGE_GET: ' - No pudimos obtener las últimas cotizaciones.',
    },
    SAVE_TITLE: 'Carga exitosa',
    SAVE_MESSAGE: 'En breve se visualizará en la tabla.',
    SAVE_MESSAGE_SCHEDULED: 'Se programó la cotización.',
  },
  MOVEMENTS: {
    ACCOUNT_NUMBER: 'Número de cuenta',
    ACCOUNT_TO: 'Cuenta del destinatario',
    BUSINESS_DATE: 'Fecha de negocio',
    CONCILIATION: 'Conciliación',
    CONCILIATION_STATUS: 'Estado de conciliación',
    DETAILS_TITLE: 'Detalles del movimiento',
    FILTERS_TITLE: 'Filtrar movimientos',
    MOVEMENT_ID: 'Id de movimiento',
    RESULT_CODE: 'Código de resultado',
    STATUS_DESCRIPTION: 'Descripción del estado',
    TITLE: 'Movimientos',
    TRACE_NUMBER: 'Número de seguimiento',
    TRANSACTION_ID: 'Id de transacción',
    TRANSACTION_TYPE: 'Tipo de transacción',
    USER_FROM: 'Usuario origen',
    USER_FROM_ADDITIONAL_DATA: 'Otros datos del originante',
    USER_TO: 'Wallet id usuario destino',
    USER_TO_ADDITIONAL_DATA: 'Otros datos del destinatario',
    ERRORS: {
      GENERIC_MOVEMENTS_FETCH_ERROR: 'Ha ocurrido un error al buscar la información de la cuenta.',
      NOT_FOUND: 'No se encontró la transacción solicitada',
      MOVEMENT_FETCH_GENERIC_ERROR: 'Ocurrio un error al buscar el movimiento',
    },
    CONCEPT: 'Concepto',
  },
  PROSPECTS: {
    ADD_PROSPECTS_FILE_TITLE: 'Pre-alta de colaboradores por archivo',
    ADD_PROSPECTS_QUICK_TITLE: 'Pre-alta rápida',
    ADD_PROSPECTS_SEND_EMAIL: 'Enviar email de registro',
    ADD_PROSPECTS_SELECTED_COMPANY:
      'Tenés seleccionada la razón social {{company}}. Podes cambiarla desde tu perfil, en el menú superior.',
    ADD_PROSPECT_QUICK_CREATION: 'Agregar colaborador',
    CSV_ERRORS_TITLE: 'Encontramos los siguientes errores en tu archivo de pre-altas:',
    DROPZONE_TEXT:
      'Arrastrá aquí un único archivo con el detalle de los colaboradores, o hacé clic para seleccionarlo',
    SEND_EMAIL: {
      TITLE: 'Envío de email de registro',
      DESCRIPTION:
        'Seleccioná el segmento deseado para enviar el email de registro a aquellos colaboradores los cuales no fueron notificados al momento de crear la pre-alta.',
      DESCRIPTION_CLIENT:
        'Se enviará el email de registro para aquellos colaboradores los cuales no fueron notificados al momento de crear la pre-alta.',
      BODY_CLIENT: '¿Desea enviar el email de registro a los colaboradores?',
      BUTTON: 'Enviar email',
      SUCCESS: 'Los emails fueron enviados correctamente',
      MODAL: {
        TITLE: '¿Querés continuar con esta acción?',
        DESCRIPTION:
          'Estás a punto de enviar el email de registro a los colaboradores del segmento <1>{{segment}}</1> ¿Estás de acuerdo?',
        DESCRIPTION_CLIENT:
          'Estás a punto de enviar el email de registro a tus colaboradores ¿Estás de acuerdo?',
      },
    },
    ERRORS: {
      CONFIRMATION_ERROR_TITLE: 'No pudimos realizar la pre-alta',
      CONFIRMATION_ERROR_DESCRIPTION:
        'Ocurrió un error inesperado durante la confirmación de la pre-alta.',
      EXPIRED_TITLE: 'No pudimos realizar la pre-alta',
      EXPIRED_DESCRIPTION:
        'Expiró el tiempo para confirmar la pre-alta. Por favor intentalo nuevamente.',
      INVALID_SEGMENT_NAME_TITLE: 'Nombre de segmento inválido',
      INVALID_FILE_NAME_DESCRIPTION:
        'El nombre del segmento enviado no coincide con ninguno registrado en la base de datos.',
      FILE_CONTAINS_ERRORS_TITLE: 'Errores en archivo',
      FILE_CONTAINS_ERRORS_DESCRIPTION:
        'Encontramos errores en el archivo que enviaste. Por favor corregilos y volvé a intentarlo',
      DEFAULT_BAD_REQUEST_ERROR: 'Ha ocurrido un error inesperado al subir el archivo',
    },
    QUICK_CREATION_MODAL: {
      TITLE: 'Agregar nuevo colaborador',
      NAME_LABEL: 'Nombre',
      LASTNAME_LABEL: 'Apellido',
      CUIL_LABEL: 'CUIL',
      EMAIL_LABEL: 'Email',
      PRODUCT_LABEL: 'Producto',
      CARD_TYPE_LABEL: 'Tipo de tarjeta',
      CATEGORY_LABEL: 'Rubro',
      BUTTON_SUBMIT_TEXT: 'Agregar',
      BUTTON_CANCEL_TEXT: 'Cancelar',
      ERRORS: {
        FIELD_REQUIRED: 'El campo es obligatorio',
        INVALID_EMAIL: 'Ingrese una dirección de correo electrónico válida',
        INVALID_CUIL: 'Formato de CUIL inválido',
        CUIL_EXIST: 'El CUIL ya existe',
        EMAIL_EXIST: 'El Email ya existe',
        INVALID_NAME: 'El nombre es inválido',
        INVALID_LASTNAME: 'El apellido es inválido',
      },
    },
    QUICK_CREATION_TABLE: {
      NAME: 'Nombre',
      LASTNAME: 'Apellido',
      CUIL: 'CUIL',
      EMAIL: 'Email',
      PRODUCT: 'Producto',
      CARD_TYPE: 'Tipo de tarjeta',
      CATEGORY: 'Rubro',
      NOT_FOUND: 'Agrega un colaborador para continuar',
    },
    MODAL_MESSAGE:
      'Estás a punto de dar de alta <1>{{total}}</1> colaboradores del segmento <3>{{segment}}</3> y razón social <5>{{company}}</5>.',
    MODAL_MESSAGE_one:
      'Estás a punto de dar de alta <1>{{total}}</1> colaborador del segmento <3>{{segment}}</3> y razón social <5>{{company}}</5>.',
    MODAL_MESSAGE_SIMPLE_CLIENTS:
      'Estás a punto de dar de alta <1>{{total}}</1> colaboradores de <3>{{company}}</3>. ¿Estás de acuerdo?',
    MODAL_MESSAGE_SIMPLE_CLIENTS_one:
      'Estás a punto de dar de alta <1>{{total}}</1> colaborador de <3>{{company}}</3>. ¿Estás de acuerdo?',
    MODAL_TITLE: '¿Querés continuar con esta acción?',
    SELECT_SEGMENT: 'Seleccioná un segmento',
    SEND_DATA: 'Enviar',
    SUCCESS_BODY:
      'En breve te estaremos enviando por correo los resultados del procesamiento de tu archivo.',
    SUCCESS_TITLE: '¡Listo! ¡Has enviado tu archivo exitosamente!',
    ERROR_TITLE: '',
    TITLE: 'Pre-alta de colaboradores',
    TITLE_QUICK_CREATION: 'Pre-alta rápida de colaboradores',
    UPLOAD_FILE: 'Subir archivo de pre-alta',
  },
  CATEGORIES: {
    GENERIC: 'ILIMITADO',
  },
  FAQ: {
    HELP: 'Ayuda',
    HELP_DIALOG: {
      TITLE: 'Cómo ingresar a la Web de Autogestión',
      INTRO:
        'Para poder acceder a nuestra Web de autogestión se precisa doble factor de autenticación. Para esto vas a precisar descargar en tu celular la aplicación Google Autenticator:',
      LINKS: {
        GOOGLE_PLAY: {
          TEXT: 'Link de descarga para Android',
          TEXTLINK: 'Google Authenticator - Apps on Google Play',
          LINK: 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US&pli=1',
        },
        APP_STORE: {
          TEXT: 'Link de descarga para Iphone',
          TEXTLINK: 'Google Authenticator',
          LINK: 'https://apps.apple.com/us/app/google-authenticator/id388497605',
        },
      },
      STEPS: {
        MAIL: 'La primera vez que ingreses a la web vas a tener que utilizar una password que te enviaremos por mail.',
        QR: 'Luego verás un QR para enrolar tu cuenta en la aplicación Autenticator.',
        CAMERA:
          'Con  la aplicación instalada y abierta en el celular sobre el margen inferior derecho de la pantalla del teléfono aparecerá un “+”, debemos ingresar allí y seleccionar “Escanear un código QR”, con ello se abrirá la cámara. ',
        SCAN: 'Debés escanear el QR que ves en pantalla.',
        DIGITS:
          'En la pantalla de inicio de sesión  ingresamos el código de 6 dígitos que nos aparece en la aplicación Autenticator con el mail que acabamos de asociar.',
        PASSWORD: 'Podés modificar tu password  haciendo click en ¿Olvidó su contraseña?',
      },
    },
    SUPPORT: 'Soporte',
  },
  USERS: {
    SELECT_ACCOUNT_NUMBER: 'Seleccioná un número de cuenta',
    ERRORS: {
      UNABLE_TO_FETCH_ACCOUNT_NUMBERS_TITLE: 'Error al buscar números de cuenta',
      UNABLE_TO_FETCH_ACCOUNT_NUMBERS_DESCRIPTION:
        'Ocurrió un error inesperado al obtener números de cuenta.',
    },
  },
  HELP_SCREEN: {
    LOGIN_PROBLEMS: {
      TITLE: 'Cómo ingresar a la Web de Autogestión',
      FIRST_ITEM:
        'Para poder acceder a nuestra Web de autogestión se precisa doble factor de autenticación. Para esto vas a precisar descargar en tu celular la aplicación Google Authenticator:',
      SECOND_ITEM:
        'La primera vez que ingreses a la web vas a tener que hacer click en “¿Olvidó su contraseña?” para que puedas establecer una de forma segura.',
      THIRD_ITEM:
        'En la pantalla de login, luego de ingresar tus credenciales verás un QR para enrolar tu cuenta en la aplicación Authenticator.',
      FOURTH_ITEM:
        'Con  la aplicación instalada y abierta en el celular sobre el margen inferior derecho de la pantalla del teléfono aparecerá un “+”, debemos ingresar allí y seleccionar “Escanear un código QR”, con ello se abrirá la cámara.',
      FIFTH_ITEM: 'Debés escanear el QR que ves en pantalla.',
      SIXTH_ITEM:
        'En la pantalla de inicio de sesión ingresamos el código de 6 dígitos que nos aparece en la aplicación Authenticator con el mail que acabamos de asociar.',
      SUBLIST_FIRST_ITEM: {
        TEXT: 'Link de descarga para Android',
        TEXTLINK: 'Google Authenticator - Apps on Google Play',
      },
      SUBLIST_SECOND_ITEM: {
        TEXT: 'Link de descarga para Iphone',
        TEXTLINK: 'Google Authenticator',
      },
    },
    MY_BALANCE: {
      TITLE: 'Mi saldo',
      FIRST_ITEM:
        'En esta sección podrás ver el saldo de tu cuenta en Nubi con el que contás para hacer dispersiones. También se listan los movimientos que acreditan o debitan saldo.',
    },
    ACCOUNTS: {
      TITLE: 'Cuentas',
      BODY: 'En este listado se encuentran las cuentas de las personas. Si una persona tiene más de una cuenta, aparecerá más de una vez.',
      FIRST_ITEM_TITLE: 'Exportar listado a Excel',
      FIRST_ITEM_BODY:
        'Se debe hacer clic en el icono con forma de archivo ubicado en la parte superior de la pantalla.',
      SECOND_ITEM_TITLE: 'Ver detalle de una cuenta',
      SECOND_ITEM_BODY: 'Se debe hacer clic en cualquier parte de la fila.',
      THIRD_ITEM_TITLE: 'Dispersar fondos',
      THIRD_ITEM_BODY:
        'Seleccionar las cuentas y luego hacer clic en Dispersar, ingresar el monto y luego confirmar.',
      FOURTH_ITEM_TITLE: 'Recuperar dinero',
      FOURTH_ITEM_BODY:
        'Solo se puede recuperar dinero si la cuenta es Corporativa o de Almuerzo desde el detalle de la cuenta. Para hacerlo, entrar al detalle de la cuenta y hacer clic en el icono que tiene 3 puntos en forma vertical que está al lado del número de cuenta y hacer clic en “Recuperar dinero”.',
      FIFTH_ITEM_TITLE: 'Inhabilitar cuenta para dispersar',
      FIFTH_ITEM_BODY:
        'Para evitar dispersarse dinero a una cuenta, deshabilitar la opción  “Habilitado para dispersión” que se encuentra en el detalle de la cuenta.',
      SIXTH_ITEM_TITLE: 'Cambiar rubro de la cuenta',
      SIXTH_ITEM_BODY:
        'Hacer clic en el icono que tiene 3 puntos en forma vertical que está al lado del número de cuenta y hacer clic en “Editar rubro”.',
    },
    CONSUMPTIONS_LIST: {
      TITLE: 'Listado de consumos',
      BODY: 'En esta sección podés visualizar los consumos que tus colaboradores realizaron con sus tarjetas Corporativas o de Almuerzo.',
      FIRST_ITEM_TITLE: 'Información que se visualiza',
      FIRST_ITEM_BODY:
        'Por defecto se muestran los consumos del último mes.\nSolo se verán consumos realizados a partir del 1° de agosto.\nLos datos se ven a día vencido, es decir, si se realizó un consumo hoy, aparecerá mañana.',
      SECOND_ITEM_TITLE: 'Exportar listado a Excel',
      SECOND_ITEM_BODY:
        'Se puede descargar en excel el listado haciendo clic en el icono similar a un archivo ubicado en la parte superior.',
      THIRD_ITEM_TITLE: 'Comprobantes',
      THIRD_ITEM_BODY:
        'Si la persona cargó el comprobante de pago desde la aplicación, en la última columna del listado aparecerá el link para descargarlo.',
      FOURTH_ITEM_TITLE: 'Comercios',
      FOURTH_ITEM_BODY:
        'Los comercios que se listan se obtienen a partir de los nombres que el comercio configuró en su terminal de pago. Desde Nubi no tenemos más información que la que se expone en el listado.',
    },
    DOWNLOAD_RECEIPTS: {
      TITLE: 'Descarga de comprobantes',
      FIRST_ITEM:
        'En esta sección podés descargar los comprobantes de los consumos que realizaron con sus tarjetas Corporativas o de Almuerzo seleccionando un año y mes específico.',
    },
    DISPERSSIONS: {
      TITLE: 'Dispersar fondos',
      FIRST_ITEM: {
        TITLE: 'Dispersar fondos > Mis dispersiones',
        BODY: 'Listado donde se visualizan todas las dispersiones de fondos realizadas.',
        FIRST_TITLE: 'Información',
        FIRST_BODY:
          'En el monto original se especifica el monto de dinero que se intentó dispersar.\nEn el monto acreditado se especifica el monto que realmente se acreditó.\nPuede haber casos en que esos valores difieran por una falla en alguna acreditación.',
        SECOND_TITLE: 'Detalle de la dispersión',
        SECOND_BODY:
          'Para ver el detalle de lo dispersado hacer clic en cualquier lugar del registro.',
      },
      SECOND_ITEM: {
        TITLE: 'Dispersar fondos > Dispersar por archivo',
        BODY: 'Esta sección es muy útil cuando se precisa dispersar dinero a muchas cuentas y montos diferentes a cada una.\nSi precisás dispersar un monto único te recomendamos hacerlo desde la sección Cuentas.',
        FIRST_TITLE: 'Descargar cuentas',
        FIRST_BODY:
          'Sirve para descargar un archivo excel con todas las cuentas incluyendo producto y rubro para que puedas identificar fácilmente en caso que algún colaborador posea más de una.',
        SECOND_TITLE: 'Procesar el archivo',
        SECOND_BODY:
          'Luego de completar la plantilla se debe subir el archivo y confirmar la dispersión.\nCuando este proceso finalice recibirás un mail con el resultado y también podrás verlo desde la sección Mis dispersiones.',
        THIRD_TITLE: 'Programar una dispersión',
        THIRD_BODY:
          'Luego de subir la plantilla se puede programar el día y hora en el que se procesarán las dispersiones.',
      },
    },
    SUPPORT_MAIL: {
      TITLE: 'Soporte para la Web de Autogestión',
      TEXT: 'Si tenés más dudas podés escribirnos a contacto_autogestion@nubi.com.ar y te responderemos con gusto.',
      FIRST_ITEM:
        'Si tenés dudas sobre la <bold>Web de Autogestión</bold>, escribinos a <CustomLink>contacto_autogestion@nubi.com.ar</CustomLink> y te responderemos tan pronto como podamos.',
      SECOND_ITEM:
        'Recordá que nuestro horario de atención es de <bold>Lunes a Viernes de 8:00 a 18:00h</bold>.',
      THIRD_ITEM:
        'Si tenés consultas sobre otros temas no relacionados con la web como altas, dispersiones y transferencias, contactanos a <CustomLink>operations@nubizcorp.com</CustomLink>',
    },
  },
  CONSUMPTION: {
    NOT_FOUND: 'No posee comprobantes',
    DOWNLOAD: 'Exportar consumos',
    XLS_FILE: 'consumos',
    JPG_FILE: 'comprobante-{{transactionId}}{{extension}}',
    CONSUMPTION_RATE: '% Consumos',
    TRX_COUNT: 'Consumos',
    CATEGORY_AMOUNT: 'Importe',
    BALANCE_AVERAGE_TX: 'Promedio consumos',
  },
};
export default translations;
