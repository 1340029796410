export const CLIENT_TRANSACTION_TYPES = {
  DISPERSION: 'COMMON:DISPERSION',
  ADJUSTMENT_DEBIT: 'COMMON:ADJUSTMENT_DEBIT',
  ADJUSTMENT_CREDIT: 'COMMON:ADJUSTMENT_CREDIT',
  BALANCE_RECOVERY: 'COMMON:BALANCE_RECOVERY',
  FUND: 'COMMON:FUNDING',
};

export const CLIENT_TRANSACTION_FILTER_FIELDS = Object.entries({
  ...CLIENT_TRANSACTION_TYPES,
  BALANCE_RECOVERY: 'COMMON:BALANCE_RECOVERY',
});

export const EXT_USERS_TRANSACTION_TYPES_LABEL_PREFIX = 'COMMON:TRANSACTIONS:EXTERNAL_USERS:';

export const EXTERNAL_USER_EMPLOYEE_TRANSACTION_TYPES = [
  'CASHOUT_AUTH',
  'CASHIN_PPCAAS',
  'CASHIN_TJURIDICA',
  'CASHIN_AUTH_CANCEL_GP',
  'ADJUST_CRED_CASHIN',
  'ADJUST_DEB_CASHOUT',
  'CASHOUT_RECOVERY_ALL',
  'CASHOUT_EXTRACASH_PRISMA',
  'CASHOUT_WITHDRAW_PRISMA',
  'RAPIPAGO_CASHOUT',
  'CASHIN_EXTRACASH_CANCEL_PRISMA',
  'CASHIN_AUTH_CANCEL_PRISMA',
  'CASHIN_WITHDRAW_CANCEL_PRISMA',
];

export const EXT_USERS_TRANSACTION_STATUS_LABEL_PREFIX = 'COMMON:STATUSES:';

export const EXTERNAL_USER_EMPLOYEE_TRANSACTION_STATUSES = ['OK', 'CANCELLED', 'REJECTED', 'ERROR'];
