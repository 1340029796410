import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Box,
  Checkbox,
  Table as MuiTable,
  Paper,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  Skeleton,
  Typography,
} from '@mui/material';
import { useTheme } from '@emotion/react';
import { IconTrash } from '@tabler/icons-react';
import { Button, ConditionalTooltip } from '@atoms';
import styles from './styles';

/**
 * Renders a paginated table with custom columns and data.
 * @param  {array}  items The data to show in the table
 * @param  {array}  columns The definitions of each column with a function to get its corresponding content from the data.
 * @param  {number} count The total amount of data available to show when paginating.
 * @param  {number} page The current page
 * @param  {number} rowsPerPage The rows to
 * @param  {boolean} [disableControls] hide pagination controls
 * @param  {array} showTooltip It gives a maxWidth of 150px and a tooltip to the cells in the array
 * @param  {(newPage: string) => void } onPageChange function to execute when user change page
 * @param  {(event: React.ChangeEvent) => void } onRowsPerPageChange function to execute when user change the number of rows per peage to be displayed
 * @param  {(object)=> void} onItemClick callback function to execute when item is clicked. It expects an item as a parameter
 * @param  {() => void=} onSelectAll callback function to execute when select all data
 * @param  {string} noResultsText text to show when no results are found
 * @param  {boolean} [showCheckbox] show checkbox to select rows
 * @param  {boolean} isSelectedAll if all data selected come true
 * @param  {array} [selectedItems] selected rows
 * @param  {(id) => void} [setSelectedItems] function to execute when user selects rows
 * @param  {string} [checkedItemProp] the name of the prop to save on selectedItems
 * @param {array} [itemsFooter] the footer items of the table
 * @param {boolean} [withPagination] if false, the pagination will be hidden
 */
const Table = ({
  checkedItemProp,
  columns,
  count = 0,
  disableControls = false,
  showTooltip = [],
  noResultsText = '',
  onDeleteRow = undefined,
  isLoading = false,
  isSelectedAll = false,
  items,
  itemsFooter = [],
  page = 0,
  rowsPerPage = 25,
  selectedItems,
  setSelectedItems,
  setIsSelectedAll,
  showCheckbox = false,
  onItemClick,
  onPageChange = () => {},
  onRowsPerPageChange,
  onSelectAll,
  isExportFile = false,
  exportFileText,
  exportFileOnClick,
  exportFileIsLoading,
  withPagination = true,
}) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const totalColumns = showCheckbox ? columns.length + 1 : columns.length;
  const isEmpty = !items || items.length === 0;

  /**
   * Function to check or uncheck one row
   * @param {Event} event
   * @param {object} item selected row's item
   */
  const handleCheck = (event, item) => {
    let newSelected = [];

    if (isSelectedAll && setIsSelectedAll) {
      setIsSelectedAll(false);
    }

    const checked = event.target.checked;
    if (checked) {
      newSelected = [...selectedItems, item];
    } else {
      newSelected = selectedItems.filter(
        (selectedItem) => selectedItem[checkedItemProp] !== item[checkedItemProp]
      );
    }
    setSelectedItems(newSelected);
  };

  /**
   * Function to check or uncheck all rows per page
   * If checked, select all items on the current page
   * if not, uncheck the items on the page
   * @param {Event} event
   */
  const handleCheckAllClick = (event) => {
    const checked = event.target.checked;
    const newSelected = selectedItems.filter((selectedItem) => {
      return !items.find((item) => item[checkedItemProp] === selectedItem[checkedItemProp]);
    });

    setSelectedItems(checked ? [...newSelected, ...items] : newSelected);

    if (!checked && setIsSelectedAll) setIsSelectedAll(false);
  };

  /**
   * Checks if an item is selected
   * @param {*} item
   * @returns {boolean} indicating if the row is selected or not.
   */
  const isItemChecked = (item) => {
    const result =
      selectedItems &&
      selectedItems.find((selectedItem) => selectedItem[checkedItemProp] === item[checkedItemProp]);
    return Boolean(result);
  };

  /**
   * This function is used to show whether the icon is checked or undetermined if there is at least one item selected on that page
   * @returns {number} how many items are checked per page
   */
  const checkedItemsPerPage = () => {
    let checkedItemCount = 0;
    items?.forEach((item) => {
      const result = selectedItems?.find(
        (selectedItem) => selectedItem[checkedItemProp] === item[checkedItemProp]
      );
      if (result) {
        checkedItemCount++;
      }
    });
    return checkedItemCount;
  };

  const itemsSelectedCount = checkedItemsPerPage();
  const isWholePageSelected = itemsSelectedCount === items?.length && !isEmpty;

  return (
    <Paper>
      <Box sx={styles.scrollableContainer}>
        <Box sx={styles.tableContainer}>
          <Box>
            {!onSelectAll && showCheckbox && selectedItems?.length > 0 && (
              <Box sx={styles.selectedRows}>
                <Trans
                  t={t}
                  i18nKey='COMMON:PAGINATION:SELECTED_ROWS'
                  count={selectedItems?.length}
                />
              </Box>
            )}
          </Box>
          {onSelectAll && (
            <>
              {selectedItems?.length > 0 ? (
                <Box style={styles.selectedRows}>
                  <Box style={styles.containerRows}>
                    <Typography color='inherit' variant='subtitle2' component='div'>
                      {isSelectedAll ? (
                        <Trans t={t} i18nKey='COMMON:PAGINATION:ALL_ROWS_SELECTED' count={count} />
                      ) : (
                        <Trans
                          t={t}
                          i18nKey='COMMON:PAGINATION:SELECTED_ROWS'
                          count={selectedItems?.length}
                        />
                      )}
                    </Typography>
                    {isWholePageSelected && selectedItems?.length !== count && (
                      <Button
                        color='primary'
                        size='small'
                        text={
                          isSelectedAll
                            ? t('COMMON:PAGINATION:CANCEL_SELECTION')
                            : t('COMMON:PAGINATION:SELECT_REMAINING_ROWS', {
                                count: count - selectedItems?.length,
                              })
                        }
                        onClick={onSelectAll}
                        customStyle={styles.styleButton}
                      />
                    )}
                  </Box>
                  {isExportFile && (
                    <Button
                      customStyle={styles.styleButton}
                      text={exportFileText}
                      color='primary'
                      size='small'
                      onClick={exportFileOnClick}
                      isLoading={exportFileIsLoading}
                    />
                  )}
                </Box>
              ) : null}
            </>
          )}
          <MuiTable>
            <TableHead>
              <TableRow>
                {showCheckbox && (
                  <TableCell key='checkbox' sx={styles.tableHead}>
                    <Checkbox
                      indeterminate={itemsSelectedCount > 0 && itemsSelectedCount < items?.length}
                      checked={isWholePageSelected}
                      disabled={isEmpty || isLoading}
                      onChange={handleCheckAllClick}
                    />
                  </TableCell>
                )}
                {columns.map((cell) => (
                  <TableCell key={cell.title}>{t(cell.title)}</TableCell>
                ))}
                {onDeleteRow && <TableCell key='deleteRow' />}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading &&
                [...Array(Number(rowsPerPage))].map((item) => (
                  <TableRow key={item}>
                    {showCheckbox && (
                      <TableCell key='checkbox'>
                        <Skeleton variant='rectangular' height={24} width={24} />
                      </TableCell>
                    )}
                    {columns.map((item) => (
                      <TableCell p={2} key={item}>
                        <Skeleton variant='rectangular' height={24} />
                      </TableCell>
                    ))}
                    {onDeleteRow && (
                      <TableCell key='deleteRow'>
                        <Skeleton variant='circular' height={24} width={24} />
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              {!isLoading && isEmpty && (
                <TableRow>
                  <TableCell p={2} colSpan={totalColumns}>
                    {noResultsText ? noResultsText : t('COMMON:NO_RESULTS_SEARCH')}
                  </TableCell>
                </TableRow>
              )}
              {!isLoading &&
                !isEmpty &&
                items?.map((item, key) => {
                  return (
                    <TableRow
                      hover={Boolean(onItemClick !== undefined)}
                      key={key}
                      sx={styles.tableRow([
                        Boolean(onItemClick !== undefined),
                        isItemChecked(item),
                      ])}
                      onClick={() => onItemClick && onItemClick(item)}
                    >
                      {showCheckbox && (
                        <TableCell>
                          <Checkbox
                            checked={isItemChecked(item)}
                            onChange={(e) => handleCheck(e, item)}
                            onClick={(e) => e.stopPropagation()}
                          />
                        </TableCell>
                      )}
                      {columns.map((cell) => {
                        return (
                          <ConditionalTooltip
                            key={cell.title}
                            tooltipText={showTooltip.includes(t(cell.title)) && cell.content(item)}
                            isTableCell
                            isShortened
                          >
                            {cell.content(item)}
                          </ConditionalTooltip>
                        );
                      })}
                      {onDeleteRow && (
                        <TableCell key='deleteRow'>
                          <IconButton onClick={() => onDeleteRow(key)}>
                            <IconTrash color={palette.primary.main} />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              {itemsFooter?.length > 0 && (
                <TableRow>
                  {[...Array(totalColumns - itemsFooter.length)].map((item) => (
                    <TableCell key={item} />
                  ))}
                  {itemsFooter.map((item, key) => (
                    <TableCell key={key}>
                      <strong>{item}</strong>
                    </TableCell>
                  ))}
                </TableRow>
              )}
            </TableBody>
          </MuiTable>
        </Box>
      </Box>
      {!disableControls && (
        <Box
          sx={[
            styles.paginationContainer,
            (onSelectAll || !showCheckbox) && styles.justifyContentRight,
          ]}
        >
          {!onSelectAll && showCheckbox && (
            <Box sx={styles.selectedRows}>
              <Trans
                t={t}
                i18nKey='COMMON:PAGINATION:SELECTED_ROWS'
                count={selectedItems?.length}
              />
            </Box>
          )}
          {withPagination && (
            <TablePagination
              component='div'
              count={count}
              onPageChange={(_event, page) => onPageChange(page)}
              onRowsPerPageChange={onRowsPerPageChange}
              labelRowsPerPage={t('COMMON:PAGINATION:ROWS_PER_PAGE')}
              page={Number(page) - 1}
              rowsPerPage={Number(rowsPerPage)}
              rowsPerPageOptions={[5, 10, 25]}
              showFirstButton={true}
              showLastButton={true}
              labelDisplayedRows={({ count }) =>
                count > 0 &&
                t('COMMON:PAGINATION:LABEL_DISPLAYED_ROWS', {
                  page,
                  total: Math.ceil(count / rowsPerPage),
                  count,
                })
              }
            />
          )}
        </Box>
      )}
    </Paper>
  );
};

Table.propTypes = {
  columns: PropTypes.array,
  count: PropTypes.number,
  items: PropTypes.array,
  onItemClick: PropTypes.func,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  page: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rowsPerPage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selectedItems: PropTypes.array,
  disableControls: PropTypes.bool,
  showTooltip: PropTypes.array,
  noResultsText: PropTypes.string,
  onDeleteRow: PropTypes.func,
  onSelectAll: PropTypes.func,
  isLoading: PropTypes.bool,
  isSelectedAll: PropTypes.bool,
  setSelectedItems: PropTypes.func,
  setIsSelectedAll: PropTypes.func,
  showCheckbox: PropTypes.bool,
  showSelectAll: PropTypes.bool,
  checkedItemProp: PropTypes.string,
  isExportFile: PropTypes.bool,
  exportFileText: PropTypes.string,
  exportFileOnClick: PropTypes.func,
  exportFileIsLoading: PropTypes.func,
  withPagination: PropTypes.bool,
  itemsFooter: PropTypes.array,
};

export default Table;
