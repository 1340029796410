import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Stack } from '@mui/material';
import {
  IconUserPlus,
  IconPencilPlus,
  IconZoomMoney,
  IconFileExport,
  IconCoin,
} from '@tabler/icons-react';
import {
  CONFIG_AMOUNT_FIELD,
  VALIDATION_AMOUNT_SCHEMA,
} from 'screens/dispersions/fastDispersion/constants';
import { Alert } from '@atoms';
import { PERMISSIONS } from '@globalConstants';
import {
  useCreateXlsx,
  useEmployees,
  useErrorSnackbar,
  useExportAllUserAccountsFiltered,
  useFastDispersion,
  useFilterInternalFeatures,
  useNameCompany,
  usePermissions,
  useProductsByCompany,
  useRecoveryEmployeeBalance,
  useURLparams,
  useUser,
} from '@hooks';
import { FullWidthContent, MainMenuLayout } from '@layouts';
import { Table, Toolbar } from '@molecules';
import { DispersionConfirmationModal, FormModal, RecoveryConfirmationModal } from '@organisms';
import { useClientStore, useCompanyStore } from '@stores';
import { filterDuplicateWalletIds } from '@utils';
import AssignNewProduct from './assignNewProduct';
import { getAlertInfo, ALERT_TYPES } from './assignNewProduct/constants';
import { COLUMNS, DEFAULT_ROWS_PER_PAGE } from './constants';
import SearchBoxClient from './searchBoxEmployees';
/**
 * Clients screen
 */
const EmployeesScreen = () => {
  const { user } = useUser();
  const { selectedCompany } = useCompanyStore();
  const { params, setURLparams } = useURLparams(
    !user.isInternal
      ? { page: 1, limit: DEFAULT_ROWS_PER_PAGE, companyId: selectedCompany }
      : undefined
  );
  const { ErrorSnackbar, showErrorSnackbar } = useErrorSnackbar();
  const navigate = useNavigate();
  const setClientSelected = useClientStore((state) => state.setClientSelected);
  const setSearchParams = useClientStore((state) => state.setSearchParams);
  const { t } = useTranslation();
  const canViewProspectsButton = usePermissions([PERMISSIONS.prospect]);
  const canViewSendMoneyButton = usePermissions([PERMISSIONS.sendMoney]);
  const canViewAddProductButton = usePermissions([PERMISSIONS.assignUserProducts]);
  const tableColumns = useFilterInternalFeatures(COLUMNS(t));
  const [differentCustomersAlertInfo, setDifferentCustomersAlertInfo] = useState(null);
  const [isShowAmountModal, setIsShowAmountModal] = useState(false);
  const [modal, setModal] = useState({ showModal: false });
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const {
    createRecoveryEmployeeBalance,
    createRecoveryEmployeeBalanceTotal,
    isLoading: isLoadingRecoveryEmployeeBalance,
    confirmRecoveryEmployeeBalance,
  } = useRecoveryEmployeeBalance();

  const companyId = user.isInternal ? params.companyId : selectedCompany;
  const { data: productsData, isLoading: isProductsLoading } = useProductsByCompany({
    companyId,
    allowRequest: Boolean(companyId),
  });

  const [confirmDispersion, setConfirmDispersion] = useState({
    openModal: false,
    id: null,
    total: null,
    count: null,
  });

  const [confirmRecovery, setConfirmRecovery] = useState({
    openModal: false,
    recoveryId: null,
    totalAmount: null,
    count: null,
    failedAccounts: [],
    failedCount: null,
  });

  const {
    data,
    isLoading,
    isValidating,
    mutate,
    handlePageChange: onPageChange,
    handleRowsPerPageChange,
    filtersOpen,
    setFiltersOpen,
    onFiltersFormSubmit,
    assignProductOpen,
    setAssignProductOpen,
    selectedItems,
    setSelectedItems,
  } = useEmployees({
    params,
    setURLparams,
    /**
     * Handle the errors of the request
     */
    onError: function handleErrors(error) {
      switch (error?.response?.status) {
        case 400:
          showErrorSnackbar(t('EMPLOYEES:ERRORS:INVALID_SEARCH'));
          break;
        default:
          if (error) {
            showErrorSnackbar(t('EMPLOYEES:ERRORS:GENERIC_ERROR'));
          }
      }
    },
  });

  const { getNameCompany } = useNameCompany();
  const customerId = selectedItems[0]?.customerId;
  const companyName = getNameCompany({
    ...(companyId && { companyId }),
    ...(customerId && { customerId }),
  });
  const isCorporate = productsData?.includes('Almuerzo') || productsData?.includes('Corporativo');

  const {
    isLoading: isLoadingDispersion,
    fastDispersion,
    confirmFastDispersion,
    isError,
    setIsError,
    errorMessage,
    createFastDispersionWithAllFilteredData,
  } = useFastDispersion();

  const { isLoading: isLoadingCreateXlsx, createXlsx } = useCreateXlsx();

  const { isLoading: isLoadingExportAll, exportAllUserAccountsFiltered } =
    useExportAllUserAccountsFiltered();

  useEffect(
    function updateCompanyIdParamWhenChangeSelectedCompany() {
      if (!user.isInternal && selectedCompany !== parseInt(params.companyId)) {
        setURLparams({ companyId: selectedCompany });
      }
    },
    [params, selectedCompany, setURLparams, user.isInternal]
  );

  /**
   * Store client data & searched params & go to movement detail screen
   */
  function navigateToDetail(client) {
    setClientSelected(client);
    setSearchParams(params);
    navigate(`/detalle-cliente/${client.username}`, {
      state: { params, account_number: client.acctNumber },
    });
  }

  /**
   * Handles the logic for checking if all selected users belong to the same customer.
   * If they do, it performs a specified action. Otherwise, it shows an alert.
   * @param {Function} action - The action to perform if all customers are the same.
   */
  const handleCustomerCheck = (action) => {
    const hasNullCustomerId = selectedItems.some((user) => user.customerId === null);
    const alertInfo = getAlertInfo(
      { error_different_customers: true, alertType: ALERT_TYPES.DIFFERENT_CUSTOMERS },
      t
    );
    if (!hasNullCustomerId) {
      const allCustomerIdsEqual = selectedItems.every((user, index, array) => {
        if (index < array.length - 1) {
          return user.customerId === array[index + 1].customerId;
        }
        return true;
      });

      if ((isSelectedAll && companyId) || (!isSelectedAll && allCustomerIdsEqual)) {
        action();
      } else {
        setDifferentCustomersAlertInfo(alertInfo);
      }
    } else {
      setDifferentCustomersAlertInfo(alertInfo);
    }
  };

  /** Handles the assignment of a new product to selected users. */
  const handleAssignNewProduct = () => handleCustomerCheck(() => setAssignProductOpen(true));

  /** Handles the dispersion of selected users.*/
  const handleDispersion = () => handleCustomerCheck(() => setIsShowAmountModal(true));

  /** Handles exporting dispersion data.*/
  const handleExportAccounts = () => {
    if (isSelectedAll) {
      exportAllUserAccountsFiltered({
        ...(selectedCompany
          ? { companyId: selectedCompany }
          : params.companyId
          ? { companyId: params.companyId }
          : {}),
        lastName: params.lastName,
        firstName: params.firstName,
        email: params.email,
        cuit: params.cuit,
        walletId: params.walletId,
        product: params.product,
        categories: params.categories,
      });
    } else {
      createXlsx(selectedItems);
    }
  };

  /**
   * Handles the retrieval of balance for selected users.
   * Checks if the selected users have the required products and if the total balance is positive.
   * If both conditions are met, it proceeds with the retrieval process. Otherwise, it displays an alert.
   */
  const handlerRetrieveBalance = async () => {
    const selectedItemsAreCorporate = selectedItems.every(
      ({ products }) => products === 'Almuerzo' || products === 'Corporativo'
    );
    if (!selectedItemsAreCorporate) {
      setDifferentCustomersAlertInfo({
        description: t('EMPLOYEES:RETRIEVE_BALANCE:PRODUCTS_NOT_ALLOWED_DESCRIPTION'),
        title: t('EMPLOYEES:RETRIEVE_BALANCE:PRODUCTS_NOT_ALLOWED_TITLE'),
        modalVisible: true,
      });
      return;
    }

    const totalBalance = selectedItems.reduce((sum, { balance }) => {
      return !isNaN(Number(balance)) ? sum + Number(balance) : sum;
    }, 0);
    if (!totalBalance > 0) {
      setDifferentCustomersAlertInfo({
        description: t('EMPLOYEES:RETRIEVE_BALANCE:POSITIVE_BALANCE_DESCRIPTION'),
        title: t('EMPLOYEES:RETRIEVE_BALANCE:POSITIVE_BALANCE_TITLE'),
        modalVisible: true,
      });
      return;
    }

    isSelectedAll ? retrieveBalanceMassive() : retrieveBalanceSelected();
  };

  /** Handles confirmation of a balance recovery for selected accounts */
  const retrieveBalanceMassive = () => {
    createRecoveryEmployeeBalanceTotal(params)
      .then((recovery) => {
        if (recovery?.recoveryId) {
          setConfirmRecovery({ openModal: true, ...recovery });
        }
      })
      .catch((e) => {
        if (e.response && e.response.status === 406) {
          setDifferentCustomersAlertInfo({
            description: t('EMPLOYEES:RETRIEVE_BALANCE:PRODUCTS_NOT_ALLOWED_DESCRIPTION'),
            title: t('EMPLOYEES:RETRIEVE_BALANCE:PRODUCTS_NOT_ALLOWED_TITLE'),
            modalVisible: true,
          });
          return;
        }
        setModal({
          showModal: true,
          title: 'EMPLOYEES:RETRIEVE_BALANCE:RECOVERY_ERROR_TITLE',
          description: 'EMPLOYEES:RETRIEVE_BALANCE:RECOVER_ERROR_BODY',
          variant: 'error',
        });
      });
  };

  /** Handles confirmation of a balance recovery for all selected accounts */
  const retrieveBalanceSelected = () => {
    const accountsInfo = selectedItems.map(({ balance, acctNumber, cuit, accountId }) => ({
      balance,
      accountNumber: Number(acctNumber),
      cuil: cuit,
      id: accountId,
    }));
    createRecoveryEmployeeBalance(companyId, accountsInfo)
      .then((recovery) => {
        if (recovery?.recoveryId) {
          setConfirmRecovery({ openModal: true, ...recovery });
        }
      })
      .catch((e) => {
        if (e.response && e.response.status === 406) {
          setDifferentCustomersAlertInfo({
            description: t('EMPLOYEES:RETRIEVE_BALANCE:PRODUCTS_NOT_ALLOWED_DESCRIPTION'),
            title: t('EMPLOYEES:RETRIEVE_BALANCE:PRODUCTS_NOT_ALLOWED_TITLE'),
            modalVisible: true,
          });
          return;
        }
        setModal({
          showModal: true,
          title: 'EMPLOYEES:RETRIEVE_BALANCE:RECOVERY_ERROR_TITLE',
          description: 'EMPLOYEES:RETRIEVE_BALANCE:RECOVER_ERROR_BODY',
          variant: 'error',
        });
      });
  };

  const buttons = [
    {
      text: t('EMPLOYEES:PROSPECT_CREATE'),
      onClick: () => navigate('/prealta/rapida'),
      icon: <IconUserPlus />,
      show: canViewProspectsButton,
    },
    {
      text: t('DISPERSIONS:DISPERSE'),
      onClick: handleDispersion,
      icon: <IconZoomMoney />,
      disabled: !selectedItems.length > 0,
      show: canViewSendMoneyButton,
      isLoading: isLoadingDispersion,
    },
    {
      text: t('EMPLOYEES:ASSIGN_PRODUCT:BUTTON'),
      onClick: handleAssignNewProduct,
      icon: <IconPencilPlus />,
      disabled: !selectedItems.length > 0,
      show: canViewAddProductButton,
    },
    {
      text: t('COMMON:EXPORT'),
      onClick: handleExportAccounts,
      icon: <IconFileExport />,
      disabled: !selectedItems.length > 0,
      isLoading: isLoadingExportAll || isLoadingCreateXlsx,
      show: false,
    },
    {
      text: t('EMPLOYEES:DETAIL:RETRIEVE_BALANCE'),
      onClick: handlerRetrieveBalance,
      icon: <IconCoin />,
      disabled: !selectedItems.length > 0,
      isLoading: isProductsLoading || isLoadingRecoveryEmployeeBalance,
      show: companyId && isCorporate,
    },
  ];
  const filteredButtons = buttons.filter((button) => button.show);

  /** Handle submit fast dispersion. */
  const handleSubmit = async ({ amount }) => {
    let fastDispersionResponse;

    setIsShowAmountModal(false);

    if (isSelectedAll) {
      fastDispersionResponse = await createFastDispersionWithAllFilteredData({
        ...(companyId ? { companyId } : { customerId }),
        amount,
        filters: {
          cuil: params.cuil,
          firstName: params.firstName,
          lastName: params.lastName,
          categories: params.categories,
          product: params.product,
        },
      });
    } else {
      fastDispersionResponse = await fastDispersion({
        employees: selectedItems,
        amount,
        ...(companyId ? { companyId } : { customerId }),
      });
    }

    const { dispersionId, total, count } = fastDispersionResponse;

    if (dispersionId && total && count) {
      setConfirmDispersion({
        openModal: true,
        id: dispersionId,
        total,
        count,
      });
    }
  };

  /** Handle submit confirmation fast dispersion. */
  const handleDispersionConfirm = async (confirm) => {
    const data = await confirmFastDispersion({
      dispersionId: confirmDispersion.id,
      confirm,
    });
    if (data) {
      if (confirm) {
        setSelectedItems([]);
        setModal({
          showModal: true,
          title: 'DISPERSIONS:FAST_DISPERSION:DISPERSIONS_SUCCESS_TITLE_INSTANT',
          description: 'DISPERSIONS:FAST_DISPERSION:DISPERSIONS_SUCCESS_BODY_INSTANT',
          variant: 'success',
        });
      }
    }
    setConfirmDispersion({ openModal: false, id: null, total: null, count: null });
  };

  /** Handle submit confirmation recovery balance */
  const handleRecoveryConfirm = (confirm) => {
    confirmRecoveryEmployeeBalance(confirmRecovery.recoveryId, confirm)
      .then(({ status }) => {
        if (status && confirm) {
          setSelectedItems([]);
          setModal({
            showModal: true,
            title: 'EMPLOYEES:RETRIEVE_BALANCE:RECOVERY_SUCCESS_TITLE',
            description: 'EMPLOYEES:RETRIEVE_BALANCE:RECOVER_SUCCESS_BODY',
            variant: 'success',
          });
          mutate();
        }
      })
      .catch(() => {
        setModal({
          showModal: true,
          title: 'EMPLOYEES:RETRIEVE_BALANCE:RECOVERY_ERROR_TITLE',
          description: 'EMPLOYEES:RETRIEVE_BALANCE:RECOVER_ERROR_BODY',
          variant: 'error',
        });
      })
      .finally(() => {
        setConfirmRecovery({ openModal: false, id: null, total: null, count: null });
      });
  };

  /** Handle action when page change */
  const handlePageChange = (page) => {
    onPageChange(page);
    if (isSelectedAll) {
      setIsSelectedAll(false);
      setSelectedItems([]);
    }
  };

  /** Handles select all data */
  const handleSelectAll = () => {
    setIsSelectedAll((prev) => {
      if (prev) {
        setSelectedItems([]);
      }

      return !prev;
    });
  };

  return (
    <MainMenuLayout>
      <FullWidthContent>
        <Stack spacing={3}>
          <SearchBoxClient
            isOpen={filtersOpen}
            onClose={() => setFiltersOpen(false)}
            params={params && params}
            isLoading={isLoading || isValidating}
            retry={mutate}
            onFiltersFormSubmit={onFiltersFormSubmit}
            disabledExportAll={!data?.page.length || !params.companyId}
          />
          <Toolbar buttons={filteredButtons} />
          <Table
            count={data?.totalCount || 0}
            items={data?.page}
            isLoading={isLoading}
            columns={tableColumns}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            onItemClick={navigateToDetail}
            page={params.page || 1}
            rowsPerPage={params.limit || DEFAULT_ROWS_PER_PAGE}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            checkedItemProp='acctNumber'
            onSelectAll={handleSelectAll}
            isSelectedAll={isSelectedAll}
            setIsSelectedAll={setIsSelectedAll}
            showCheckbox
          />
        </Stack>
        <AssignNewProduct
          assignProductOpen={assignProductOpen}
          setAssignProductOpen={setAssignProductOpen}
          selectedUsers={filterDuplicateWalletIds(selectedItems)}
        />
        <ErrorSnackbar />
        <Alert
          modalVisible={differentCustomersAlertInfo?.modalVisible}
          handleClose={() => setDifferentCustomersAlertInfo(null)}
          variant={differentCustomersAlertInfo?.variant}
          title={differentCustomersAlertInfo?.title}
          description={differentCustomersAlertInfo?.description}
          closeButtonText={t('COMMON:ACCEPT')}
        />
        <FormModal
          title='DISPERSIONS:FAST_DISPERSION:TITLE_AMOUNT'
          description='DISPERSIONS:FAST_DISPERSION:DESCRIPTION_AMOUNT'
          isOpen={isShowAmountModal}
          validationSchema={VALIDATION_AMOUNT_SCHEMA}
          configFields={CONFIG_AMOUNT_FIELD}
          onClose={() => setIsShowAmountModal(false)}
          initialValues={{ amount: '' }}
          showClearFilter={false}
          onSubmit={handleSubmit}
        />
        <DispersionConfirmationModal
          openModal={confirmDispersion.openModal}
          handleConfirmation={handleDispersionConfirm}
          total={confirmDispersion.total}
          count={confirmDispersion.count}
          companyName={companyName}
          submitLoading={isLoadingDispersion}
        />
        <RecoveryConfirmationModal
          isOpen={confirmRecovery.openModal}
          handleConfirmation={handleRecoveryConfirm}
          totalAmount={confirmRecovery.totalAmount}
          count={confirmRecovery.count}
          companyName={companyName}
          failedCount={confirmRecovery.failedCount}
          submitLoading={isLoadingRecoveryEmployeeBalance}
        />
        <Alert
          modalVisible={isError}
          handleClose={() => setIsError(false)}
          variant='error'
          title={errorMessage.title}
          description={errorMessage.description}
        />
        <Alert
          modalVisible={modal.showModal}
          handleClose={() => setModal({ ...modal, showModal: false })}
          variant={modal.variant}
          title={modal.title}
          description={modal.description}
        />
      </FullWidthContent>
    </MainMenuLayout>
  );
};

EmployeesScreen.propTypes = {};

export default EmployeesScreen;
