//This file lists all endpoints our app interacts with.
//Each endpoint also has a list of errors that should be allowed by the interceptor to be 'passed on' (the promise will be rejected with the value of that error).
//If the 'all' property is truthy, then any error coming from that endpoint will be passed on.

const all = true;

export const staticEndpoints = {
  '/auth': [
    {
      code: 'USER_OR_PASSWORD_INVALID',
      status: 406,
    },
  ],
  '/client/B2B-Segments': [{ all }],
  '/partner/companies': [
    {
      status: 404,
    },
  ],
  '/client/search': [
    {
      status: 400,
    },
  ],
  '/client/search-account': [
    {
      status: 400,
    },
  ],
  '/client/consumption': [
    {
      status: 400,
    },
  ],
  '/client/requestPhysicalCardBusiness': [
    {
      status: 400,
    },
  ],
  '/s3/bulk-prospect-create': [
    {
      status: 400,
    },
    {
      status: 404,
    },
  ],
  '/s3/confirm-prospect-create': [
    {
      status: 404,
    },
  ],
  '/s3/dispersion': [
    {
      status: 400,
    },
    {
      status: 404,
    },
  ],
  '/s3/dispersion-json': [
    {
      status: 400,
    },
  ],
  '/commerce': [
    {
      status: 400,
    },
  ],
  '/partner/reports': [
    {
      status: 400,
    },
    { status: 404 },
  ],
  '/client/assign-product': [
    {
      status: 400,
    },
  ],
  '/partner/customer/groups': [{ status: 400 }],
  '/partner/customer/add-client': [
    {
      status: 400,
    },
  ],
  '/client/prospects/send-notification': [],
};

export const dynamicEndpoints = [
  {
    url: /^\/client\/account-detail\/[\w-]+$/,
    method: 'get',
    all,
  },
  {
    url: /^\/client\/segment-or-email\/[\w-]+$/,
    method: 'patch',
    errors: [
      {
        status: 409,
      },
    ],
  },
  {
    url: /^\/client\/[\w-]+\/products\/[\w-]+$/,
    method: 'get',
    all,
  },
  {
    url: /^\/transactions\/userMovements\/[\w-]+$/,
    method: 'get',
    errors: [],
  },
  {
    url: /^\/prospects\/send-notification\/[\w-]+$/,
    method: 'post',
    errors: all,
  },
  {
    url: /^\/transactions\/userMovementDetail\/[\w-]+$/,
    method: 'get',
    errors: [
      {
        status: 404,
      },
    ],
  },
  {
    url: /^\/client\/retrieve-balance$/,
    method: 'post',
    errors: all,
  },
  { url: /^\/client\/change-benefit\/[\w-]+$/, method: 'patch', errors: all },
  {
    url: /^\/s3\/voucher\?entityId=\d+&time=\d{6}$/,
    method: 'get',
    errors: all,
  },
  {
    url: /^\/s3\/zipped-vouchers\?entityId=\d/,
    method: 'get',
    errors: all,
  },
  { url: /^\/client\/prospect\/[\w-]+$/, method: 'patch', errors: all },
  { url: /^\/client\/[\w-]+$/, method: 'patch', errors: all },
  { url: /^\/partner\/customer\/assign-product\/[\w-]+$/, method: 'post', errors: { status: 404 } },
  { url: /^\/client\/productsV2\/[\w-]+$/, method: 'get', errors: all },
  { url: /^\/client\/user-product\/[\w-]+$/, method: 'patch', errors: all },
  {
    url: /^\/partner\/customer\/segment\/[a-zA-Z0-9-]+(\?segmentName=[a-zA-Z0-9-% ]+)?$/,
    method: 'post',
    errors: { status: 400 },
  },
  {
    url: /^\/dispersion\/[\w-]+$/,
    method: 'delete',
    errors: all,
  },
  {
    url: /^\/partner\/address\?customerId=\d/,
    method: 'get',
    errors: { status: 404 },
  },
  {
    url: /^\/client\/balance-recovery-json$/,
    method: 'post',
    errors: all,
  },
  {
    url: /^\/client\/confirm-recovery$/,
    method: 'put',
    errors: all,
  },
  {
    url: /^\/client\/balance-recovery\/total$/,
    method: 'post',
    errors: all,
  },
];
